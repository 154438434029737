<template>
<div>
    <div>
    <b-card>

        <b-form @submit="onSubmit">
        <b-row>
            <b-col md="4" xl="4" xs="6" class="mb-1"> <b-form-group
                id="name"
                label="Name:"
                label-for="name"
            >
                <b-form-input
                id="name"
                v-model="trainingCandidate.name"
                type="text"
                required
                ></b-form-input>
                <small class="text-muted" v-if="errors.name"> {{ errors.name }} </small>
            </b-form-group> </b-col>
            <b-col md="4" xl="4" xs="6" class="mb-1"> <b-form-group
                id="dob"
                label="Date of Birth:"
                label-for="dob"
            >
                <b-form-datepicker
                id="dob"
                v-model="trainingCandidate.dob"
                :value="trainingCandidate.dob"
                
                ></b-form-datepicker>
                
            </b-form-group> </b-col>
            <b-col md="4" xl="4" xs="6" class="mb-1"> <b-form-group
                id="vaccinationStatus"
                label="Vaccination Status:"
                label-for="vaccinationStatus"
            >
                <b-form-checkbox-group v-model="trainingCandidate.vaccinationStatus">
                <b-form-checkbox value="true">Yes</b-form-checkbox>
                <b-form-checkbox value="false">No</b-form-checkbox>
                </b-form-checkbox-group>
                <small class="text-muted" v-if="errors.vaccinationStatus"> {{ errors.vaccinationStatus }} </small>
            </b-form-group> </b-col>
            <b-col md="4" xl="4" xs="6" class="mb-1"> <b-form-group
                id="eAadharStatus"
                label="eAadhar Status:"
                label-for="eAadharStatus"
            >
                <b-form-checkbox-group v-model="trainingCandidate.eAadharStatus">
                <b-form-checkbox value="true">Yes</b-form-checkbox>
                <b-form-checkbox value="false">No</b-form-checkbox>
                </b-form-checkbox-group>
                <small class="text-muted" v-if="errors.eAadharStatus"> {{ errors.eAadharStatus }} </small>
            </b-form-group> </b-col>
            <b-col md="4" xl="4" xs="6" class="mb-1"> <b-form-group
                id="mobile"
                label="Mobile:"
                label-for="mobile"
            >
                <b-form-input
                id="mobile"
                v-model="trainingCandidate.mobile"
                type="tel"
                required
                ></b-form-input>
                <small class="text-muted" v-if="errors.mobile"> {{ errors.mobile }} </small>
            </b-form-group> </b-col>
            <b-col md="4" xl="4" xs="6" class="mb-1"> <b-form-group
                id="email"
                label="Email:"
                label-for="email"
            >
                <b-form-input
                id="email"
                v-model="trainingCandidate.email"
                type="email"
                required
                ></b-form-input>
                <small class="text-muted" v-if="errors.email"> {{ errors.email }} </small>
            </b-form-group> </b-col>
            <b-col md="4" xl="4" xs="6" class="mb-1"> <b-form-group
                id="fatherName"
                label="Father's Name:"
                label-for="fatherName"
            >
                <b-form-input
                id="fatherName"
                v-model="trainingCandidate.fatherName"
                type="text"
                
                ></b-form-input>
            </b-form-group> </b-col>
            <b-col md="4" xl="4" xs="6" class="mb-1"> <b-form-group
                id="motherName"
                label="Mother's Name:"
                label-for="motherName"
                >
                <b-form-input
                    id="motherName"
                    v-model="trainingCandidate.motherName"
                    type="text"
                    
                ></b-form-input>
            </b-form-group> </b-col>
            <b-col md="4" xl="4" xs="6" class="mb-1"> <b-form-group
            id="aadharNo"
            label="Aadhar Number:"
            label-for="aadharNo"
            >
            <b-form-input
                id="aadharNo"
                v-model="trainingCandidate.aadharNo"
                type="number"
                required
            ></b-form-input>
            <small class="text-muted" v-if="errors.aadharNo"> {{ errors.aadharNo }} </small>
            </b-form-group> </b-col>
            <b-col md="4" xl="4" xs="6" class="mb-1"> <b-form-group
            id="location"
            label="Location:"
            label-for="location"
            >
            <b-form-input
                id="location"
                v-model="trainingCandidate.location"
                type="text"
                required
            ></b-form-input>
            <small class="text-muted" v-if="errors.location"> {{ errors.location }} </small>
            </b-form-group> </b-col>
            <b-col md="4" xl="4" xs="6" class="mb-1"> <b-form-group
            id="address"
            label="Address:"
            label-for="address"
            >
            <b-form-textarea
                id="address"
                v-model="trainingCandidate.address"
                rows="3"
                
            ></b-form-textarea>
            </b-form-group> </b-col>
            <b-col md="4" xl="4" xs="6" class="mb-1"> <b-form-group
            id="pincode"
            label="Pincode:"
            label-for="pincode"
            >
            <b-form-input
                id="pincode"
                v-model="trainingCandidate.pincode"
                type="number"
                
            ></b-form-input>
            </b-form-group> </b-col>
            <b-col md="4" xl="4" xs="6" class="mb-1"> <b-form-group
            id="qualification"
            label="Qualification:"
            label-for="qualification"
            >
            <b-form-input
                id="qualification"
                v-model="trainingCandidate.qualification"
                type="text"
                required
            ></b-form-input>
            <small class="text-muted" v-if="errors.qualification"> {{ errors.qualification }} </small>
            </b-form-group> </b-col>
            <b-col md="4" xl="4" xs="6" class="mb-1"> <b-form-group
            id="max_marks"
            label="Maximum Marks:"
            label-for="max_marks"
            >
            <b-form-input
                id="max_marks"
                v-model="trainingCandidate.max_marks"
                type="number"
                
            ></b-form-input>
            </b-form-group> </b-col>
            <b-col md="4" xl="4" xs="6" class="mb-1"> <b-form-group
            id="marks_obtained"
            label="Marks Obtained:"
            label-for="marks_obtained"
            >
            <b-form-input
                id="marks_obtained"
                v-model="trainingCandidate.marks_obtained"
                type="number"
                
            >
            </b-form-input>
            </b-form-group> </b-col>
            <b-col md="4" xl="4" xs="6" class="mb-1"> <b-form-group
            id="college_name"
            label="College Name:"
            label-for="college_name"
            >
            <b-form-input
                id="college_name"
                v-model="trainingCandidate.college_name"
                type="text"
                
            ></b-form-input>
            </b-form-group> </b-col>
            <b-col md="4" xl="4" xs="6" class="mb-1"> <b-form-group
            id="university_name"
            label="University Name:"
            label-for="university_name"
            >
            <b-form-input
                id="university_name"
                v-model="trainingCandidate.university_name"
                type="text"
                
            ></b-form-input>
            </b-form-group> </b-col>
        </b-row>
            
            <b-button type="submit" variant="primary">Submit</b-button>
            <b-button type="reset" variant="secondary">Reset</b-button>
        </b-form>
    </b-card>
</div>
</div>  
</template>
<script>
import {

  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox, BFormCheckboxGroup,BFormDatepicker,
  BCard,
  BRow,
  BCol,
  BFormFile,
  BFormTextarea,
  BButton, BSidebar, 
} from "bootstrap-vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TrainingBatchService from '@/services/training-batch-service'
import TrainingCandidateService from '@/services/training-candidate-service'

export default {
  components: {
    BSidebar, BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox, BFormCheckboxGroup,BFormDatepicker,
    BFormInvalidFeedback,
    BCard,
    BRow,
    BCol,
    BFormFile,
    BFormTextarea,
    // Form Validation
    
  },
  
  data() {
    return {
        trainingCandidate: {
            name: '',
            dob: '',
            vaccinationStatus: '',
            eAadharStatus: '',
            mobile: '',
            email: '',
            fatherName: '',
            motherName: '',
            aadharNo: '',
            location: '',
            address: '',
            pincode: '',
            qualification: '',
            max_marks: '',
            marks_obtained: '',
            college_name: '',
            university_name: '',
      },
      errors: {},
      error: '',
      batch_id: ''
    }      
  },
  
  created() {
    this.batch_id = this.$route.params.id;
    this.trainingCandidate.batch_id = this.batch_id
  },
  methods: {
    
    onSubmit() {
      this.errors = {}
      if (!this.trainingCandidate.name) {
        this.errors.name = 'Name is required'
      }
      if (!this.trainingCandidate.dob) {
        this.errors.dob = 'Date of Birth is required'
      }
      if (!this.trainingCandidate.vaccinationStatus) {
        this.errors.vaccinationStatus = 'Vaccination Status is required'
      }
      if (!this.trainingCandidate.eAadharStatus) {
        this.errors.eAadharStatus = 'eAadhar Status is required'
      }
      if (!this.trainingCandidate.mobile) {
        this.errors.mobile = 'Mobile is required'
      }
      if (!this.trainingCandidate.email) {
        this.errors.email = 'Email is required'
      }
    //   if (!this.trainingCandidate.fatherName) {
    //     this.errors.fatherName = "Father's Name is required"
    //   }
    //   if (!this.trainingCandidate.motherName) {
    //     this.errors.motherName = "Mother's Name is required"
    //   }
      if (!this.trainingCandidate.aadharNo) {
        this.errors.aadharNo = 'Aadhar Number is required'
      }
      if (!this.trainingCandidate.location) {
        this.errors.location = 'Location is required'
      }
    //   if (!this.trainingCandidate.address) {
    //     this.errors.address = 'Address is required'
    //   }
    //   if (!this.trainingCandidate.pincode) {
    //     this.errors.pincode = 'Pincode is required'
    //   }
      if (!this.trainingCandidate.qualification) {
        this.errors.qualification = 'Qualification is required'
      }
    //   if (!this.trainingCandidate.max_marks) {
    //     this.errors.max_marks = 'Maximum Marks is required'
    //   }
    //   if (!this.trainingCandidate.marks_obtained) {
    //     this.errors.marks_obtained = 'Marks Obtained is required'
    //   }
    //   if (!this.trainingCandidate.college_name) {
    //     this.errors.college_name = 'College Name is required'
    //   }
    //   if (!this.trainingCandidate.university_name) {
    //     this.errors.university_name = 'University Name is required'
    //   }
        // Additional validation for email
        if (this.trainingCandidate.email && !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.trainingCandidate.email)) {
            this.errors.email = 'Invalid email format'
        }
        
        // Additional validation for mobile
        if (this.trainingCandidate.mobile && !/^\d{10}$/.test(this.trainingCandidate.mobile)) {
            this.errors.mobile = 'Invalid mobile number format'
        }
        
        // Additional validation for pincode
        if (this.trainingCandidate.pincode && !/^\d{6}$/.test(this.trainingCandidate.pincode)) {
            this.errors.pincode = 'Invalid pincode format'
        }

        if (Object.keys(this.errors).length === 0) {
            // No errors, submit the form
            // Use axios or any other method to submit the data to the server
            TrainingCandidateService.createTrainingCandidate(this.trainingCandidate).then( res => {
                console.log(res)
                if(res.status === 200) {
                console.log(res.data)
                this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Candidate Add Successful',
                        icon: 'EditIcon',
                        variant: 'success',
                      },
                })
                this.$router.push({ path: '/apps/train-n-hire/view/' + this.batch_id})
                } else if (res.status === 404) {
                //this.batches = []
                this.error = "No Batches added in the system yet"
                this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Candidate Add Failed',
                        icon: 'EditIcon',
                        variant: 'danger',
                      },
                })
                } else if (res.status === 500) {
                //this.batches = []
                this.error = "Some error occurred, error meesage: " + res.message + "    Error: " + res.error
                this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Candidate Add Failed',
                        icon: 'EditIcon',
                        variant: 'danger',
                      },
                })
                } else if ((res.status === 401 ) || (res.status === 403 )) {
                  window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
                //this.batches = []
                this.error = "Session Expired... Redirecting to Login Page"
                
                localStorage.clear();
                    this.$router.push({ name: "auth-login" });
                }
            })
        }
    }
  
}
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-candidate-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.b-sidebar.sidebar-lg {
  width: 45rem;
  padding-left: 15px;
  padding-right: 15px;
}
.hidden{
  display: none;
}
</style>
  